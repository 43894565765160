.header-banner {
  background-color: #404040;

  @media (max-width: 650px) {
    display: none;
  }
  p {
    color: $white;
    //text-align: center;
    margin: 0;
    text-transform: uppercase;
    line-height: 35px;
    font-size: 12px;
    font-weight: 700;
  }
}