.top-bar {
  //min-height: 48px;
  padding: 15px 0;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__socials {
    display: flex;
    margin: 0;

    li {
      margin-right: 7px;

      i {
        color: $white;
        font-size: 1.8rem;
      }
    }
  }
  &__links {
    display: flex;
    margin: 0;

    li {
      padding-left: 12px;

      a {
        color: rgba(255,255,255,0.8);
        font-size: 14.4px;
      }

      &:not(:last-of-type) {
        padding-right: 12px;
        position: relative;

        &::after {
          content: '';
          background-color: $white;
          height: 16px;
          width: 1px;
          top: calc(50% - 8px);
          right: 0;
          position: absolute;
        }
      }
    }
  }
}