#order, #order-opc {
  #cart_summary {
    border-left: none;
    border-right: none;
    border-top: none;
    &, * {
      background: none;

      td,
      th {
        border-left: none;
        border-right: none;
      }

      img {
        border: none !important;
      }
    }
    .first_item {
      text-align: left;
    }
    .cart_product {
      a {
        display: block;
      }
    }
    .price {
      color: $dark;
      font-size: 17px;
    }
    .product-name {
      a {
        color: $dark;
        font-size: 16px;
        display: block;
      }
    }
    thead {
      th {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 300;
        color: $dark;
      }
    }
    .button-minus,
    .button-plus{
      background: none !important;
      border: none !important;
      padding: 0 !important;
      color: $dark !important;
    }

  }

  .cart_avail {
    color: $dark;

    span {
      color: inherit;
    }
  }

  .regul_amin {
    color: red !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    text-shadow: none !important;
  }
}

.step {
  li {
    span,
    a {
      &:after {
        display: none !important;
      }
    }

    &.step_done {
      background: $white !important;

      a {
        color: $red !important;
        text-shadow: none !important;
      }
    }

    &.step_todo {
      span {
        color: $dark !important;
        background-color: $white;
      }
    }
  }
}