@import "variables";
@import "basic";

@import "parials/header";
@import "parials/footer";
@import "parials/home";
@import "parials/product";
@import "parials/breadcrumb";
@import "parials/category";
@import "parials/page-product";
@import "parials/cart";

@import "parials/items";