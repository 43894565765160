@import "home/static-block";
@import "home/home-desc";
@import "home/new-products";


.bannerSequence {
  @media (max-width: 1200px) {
    margin-bottom: 30px;
  }
}

.static_top .title_top h4 {
  @media (max-width: 1199px) {
    line-height: 40px;

    a {
      font-size: 16px;
    }
  }
}