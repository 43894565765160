#center_column .title_block {
  display: block !important;
}

#thumbs_list_frame {
  display: flex;
  flex-wrap: wrap;

  li {
    margin-right: 10px;

    a {
      display: block;
    }
  }
}
.pb-left-column #image-block {
  border: none !important;
}

#product {
  .idTabs {
    a {
      color: $dark;
    }
  }

  .page-subheading {
    border: none;
  }
}

#more_info_block {
  border: 1px solid rgba(0,0,0,0.1);

  #more_info_tabs {
    background-color: #eee;
    border: none;
    margin-bottom: 0;

    li {
      position: relative;
      border-bottom: none;

      &:not(:first-of-type) {
        margin-left: 1px;
        &:before {
          content: '';
          height: 20px;
          width: 1px;
          background-color: rgba(0,0,0,0.1);
          display: inline-block;
          position: absolute;
          left: -1px;
          top: 50%;
          margin-top: -10px;
        }
      }
      a {
        color: rgba(0,0,0,0.8);
        margin: 0;
        padding: 18px 20px 17px;
        transition: all 350ms;
        font-family: inherit;

        font-size: 14px;
        letter-spacing: 0.4px;
        background: none;
        text-transform: uppercase;
        border: none;
        background: transparent;
        border-radius: 0;
        line-height: 1;

        &.selected,
        &:hover {
          background-color: $white;

          &:after {
            display: none !important;
          }
        }
      }
    }
  }
}
#more_info_sheets {
  padding: 24px 30px;
}
.star_content {
  .cancel {
    display: none;
    visibility: hidden;
  }
  .radio {
    span {
      background: none;
    }
  }
  .star {
    a {
      font-size: 0;
    }
  }
}

#new_comment_form .new_comment_form_content {
  background: none;
}


.page-product__title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: .4px;
  text-transform: uppercase;
  color: $dark !important;

  &--bigger {
    font-size: 21px;
  }
}
#new_comment_form label {
  font-size: 14px;
  font-weight: 400;
}
#new_comment_form #criterions_list {
  border-bottom: none;
}
#new_comment_form input,
#new_comment_form textarea {
  border-width: 1px;
  padding: 6px 12px;
  font-size: 14px;
  color: rgb(17, 17, 17);
  background-color: #fff;
  border-color: rgba(0,0,0,.1);
  line-height: 36px;
  margin-bottom: 20px;

  &:focus {
    outline: none;
  }

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: rgb(17, 17, 17);
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: rgb(17, 17, 17);
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: rgb(17, 17, 17);
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: rgb(17, 17, 17);
  }
}

#new_comment_form input {
  height: 36px;
}
.title_block {
  font-weight: bold;
  font-size: 14px;
  color: rgba(0,0,0,0.8);
  margin-bottom: 9px;
  display: block;
}
.comment_author_infos {
  color: rgba(0,0,0,0.5);
  margin-bottom: 9px;

  strong {
    font-weight: 400;
  }
}
.comment_author {
  .star {
    &:after {
      color: #cccccc !important;
    }
    &_on {
      &:after {
        color: #ffd33c !important;
      }
    }
  }
}
.comment_content {
  font-size: 13px;
  line-height: 22px;
  color: rgba(0,0,0,0.5);
  padding-top: 20px;
  padding-bottom: 20px;
}
#product_comments_block_tab div.comment {
  border-top: none;
  border-bottom: 1px solid #d6d4d4;;
}
.rating_title {
  display: inline-block;
  font-family: inherit;
  color: rgba(0,0,0,0.5);
  font-size: 14px;
  line-height: 2em;
  min-width: 50px;
  float: none !important;
  font-weight: 400 !important;
}
.star_content {
  div.star {
    &.star_hover,
    &.star_on {
      &:after {
        color: gold !important;
      }
    }
  }
}

.quantity__box {
  position: relative;
  width: 80px;
  height: 40px;

  #quantity_wanted {
    width: 100%;
    height: 40px;
    line-height: 38px;
  }

  .button-minus {
    left: 0;

  }
  .button-plus {
    right: 0;

  }

  .button-minus,
  .button-plus {
    position: absolute;
    top: 0;
    opacity: 0;
    transition: 0.3s;
    cursor: pointer;
    border: none;
    width: 20px;
    height: 100%;
    padding: 0 !important;
    line-height: 38px !important;

    @media (max-width: 991px) {
      opacity: 1;
    }

    &:hover {

    }

    i {
      font-size: 9px;
      width: 100%;
      line-height: 39px;
      transition: 350ms;
      font-weight: 400;

      &:hover {
        color: #111;
        background-color: rgba(0,0,0,0.1);
      }
    }
  }

  &:hover {
    .button-minus,
    .button-plus {
      opacity: 1;
    }
  }
}

.quantity__box,
.cart_box {
  float: left;
  margin-right: 5px;
}

#our_price_display {
  font-size: 20px;
  font-weight: 400;
  padding: 0;
  font-family: 'Overpass', sans-serif;
  line-height: 1;
}
#old_price_display {
  .price {
    font-size: 16px !important;
    color: #aaaaaa !important;
    font-weight: 700 !important;
  }
}
.product_info_extra {
  border-bottom-color: rgba(0,0,0,.1);
}

div.selector {
  width: 100% !important;
  background: none !important;
  border: 1px solid #D6D4D4;
  margin-bottom: 30px;
  &:hover,
  &:focus {
    span {
      background-position: right -1px top -1px;
    }
  }

  span {
    width: 100% !important;
    background-position: right -1px top -1px;

    &:hover,
    &:focus {
      background-position: right -1px top -1px;
    }
  }
}

#attributes .attribute_list .form-control {
  max-width: 100%;
}

#product_reference {
  margin-top: 20px;
}