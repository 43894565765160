.btn,
.button {
  display: inline-block;
  background: none !important;
  height: 40px;
  line-height: 38px !important;
  border: 1px solid;
  padding: 0 20px !important;
  transition: all 350ms;
  text-transform: uppercase;
  font-weight: 400 !important;
  font-size: 14px !important;
  -webkit-border-radius: 0!important;
  -moz-border-radius: 0!important;
  border-radius: 0!important;

  span {
    padding: 0 !important;
    border: none !important;
    font-size: inherit;
    transition: all 350ms;

    &:hover,
    &:focus {
      background: none !important;
    }
  }

  &-default {
    background-color: $red !important;
    color: $white !important;
    border-color: $red !important;

    &:hover,
    &:focus {
      color: $black !important;
    }
  }

  &-black {
    background-color: $dark !important;
    color: $white !important;
    border-color: $dark !important;

    &:hover,
    &:focus {
      background-color: $red !important;
      color: $white !important;
      border-color: $red !important;
    }
  }
  &-small {
    line-height: 40px;
    background-color: $red !important;

    &:hover {
      span {
        color: $black;
      }
    }
    span {
      display: block;
      line-height: 40px;
      color: $white;
    }
  }
}