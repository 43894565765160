.product-container {
  border: none !important;
  position: relative;
  margin-bottom: 30px;

  .product-image-container {
    position: relative;

    .product_img_link {
      display: block;
      padding-bottom: 250px;
      position: relative;

      @media (max-width: 767px) {
        padding-bottom: 100%;
      }
    }

    img {
      opacity: 1;
      z-index: 0 !important;
      transition: 0s !important;
      position: absolute !important;
      top: 0;
      left: 50%;
      transform: translateX(-50%) !important;
      width: 100%;
      max-width: 250px;
      &:nth-of-type(2) {
        opacity: 0 !important;
        visibility: hidden !important;
      }
    }
  }

  &:hover {
    .product-image-container {
      img {
        &:nth-of-type(1) {
          opacity: 0 !important;
          visibility: hidden !important;
        }
        &:nth-of-type(2) {
          opacity: 1 !important;
          visibility: visible !important;
        }
      }
    }
  }


  .right-block {
    padding: 0 !important;
    background: none !important;
    border: none !important;
  }

  .product-name {
    color: #111111 !important;
    font-size: 14px !important;
    font-weight: 700 !important;
  }

  .price {
    color: rgba(0,0,0,0.3) !important;
    font-size: 22px !important;
    font-weight: 700 !important;
  }
  .content_price {
    padding-top: 5px !important;
  }
  .addToWishlist {
    position: absolute;
    top: 0;
    right: 0;
    height: 40px;
    width: 40px;
    transition: all 350ms;
    text-align: center;
    line-height: 40px;

    &:hover {
      background-color: rgba(0,0,0,0.1);
    }
  }
  .product-price {
    display: block;
    float: none !important;
  }
  .promo-price {
    color: $red !important;
  }

  .quick-view-wrapper-mobile {
    position: absolute;
    bottom: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    transition: all 350ms;
  }

  &:hover {
    .quick-view-wrapper-mobile {
      visibility: visible;
      opacity: 1;
      right: 10px;
    }
  }
  .right-block {
    margin-top: 20px;
  }
}

#attributes .attribute_list #color_to_pick_list li {
  padding: 0;
  border: 0 !important;
  width: 30px;
  height: 30px;

  a.color_pick {
    height: 100%;
    width: 100%;
    border: solid 1px rgb(238, 238, 238);

    img {
      height: 100%;
      width: 100%;
    }
  }

  &.selected a {
    border-color: black;
  }
}

.new-box {
  padding: 6px;
  z-index: 3;
  background-color: #da2127 !important;
  text-align: center;
  font-size: 11px;
  line-height: 1;
  letter-spacing: -.4px;
  font-family: 'Arial',sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.06);
  -moz-box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.06);
  box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.06);
  color: #fff !important;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 10px;
  width: auto !important;
  height: auto !important;
}