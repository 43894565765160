.breadcrumb {
  background: none !important;
  border: none !important;

  * {
    color: $dark !important;
    font-size: 13px !important;
  }

  a {
    background: none !important;
    margin: 0 !important;
    padding: 0;

    &:after,
    &:before {
      display: none !important;
    }
  }
  .navigation-pipe {
    text-align: center;
    text-indent: 0;
  }
}