@import "header/promo-bar";
@import "header/right-bar";
@import "header/top-bar";

header {
  background-color: #1b1b1b;
  transition: all 350ms;

  //.container {
  //  max-width: 1900px;
  //}
}

.nav_container {
  background: transparent;
  padding: 20px 0;

  .row {
    display: flex;
    align-items: center;
  }
}

img.logo {
  max-height: 55px;
  max-width: 150px;


  @media (max-width: 767px) {
    max-height: 35px;
  }
}

body.active {
  header {
    position: fixed;
    top: 0;
    width: 100%;
    //border: 1px solid $white;
    background-color: rgb(17, 17, 17);

    @keyframes example {
      from {
        top: -100px;}
      to {
        top: 0;}
    }

    animation-name: example;
    animation-duration: 0.5s;

    .nav_container {
      padding: 5px 0;
    }

    img.logo {
      max-height: 20px;
    }
  }
}

#header_logo {
  padding: 0 !important;
  display: inline-block;
}

.header__socials {
  a {
    margin-left: 10px;
    color: #ffffff;

    i {
      font-size: 20px;
    }
  }
}