footer {
  background-color: #222222;
  padding: 100px 0 50px;

  @media (max-width: 991px) {
    padding: 60px 0 40px;
  }
  @media (max-width: 767px) {
    padding: 20px 0 0;
  }
  h4 {
    color: #fff !important;
    margin: 0 0 15px !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    position: relative !important;
    text-transform: uppercase !important;
    letter-spacing: 0.2px;

    &:before,
    &:after {
      display: none !important;
    }
  }
  .toggle-footer {
    display: block !important;
  }

  #social_block {
    padding-top: 0 !important;

    a {
      border: none !important;
      text-align: left !important;
      color: white !important;
      width: 22px !important;
      height: 26px !important;
      margin-right: 10px;

      i {
        font-size: 20px;
      }
    }
  }
  #newsletter_block_left {
    padding-top: 0 !important;
  }


  ul, ol {
    li {

    }
  }

  a, p, span {
    padding: 4px 0 4px !important;
    display: inline-block !important;
    margin-bottom: 2.6px;
    font-size: 14px !important;
    line-height: 20px !important;
    color: rgba(255,255,255,0.5) !important;
    font-weight: 600;

    &:before {
      display: none;
    }
  }

  .footer-block {
    margin-top: 0 !important;
  }
}

.blockFooter3 {
  background: none;
}
.bottom-bar {
  background: none;
  border-top: 1px solid rgba(255, 252, 252, 0.10);
  padding-top: 40px;
}