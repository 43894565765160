body {
  overflow-x: hidden;
  width: 100%;
  transition: margin-left 350ms;
  color: #000;

  * {
    font-family: 'Overpass', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  i {
    font-family: FontAwesome !important;
  }
}

article {
  h1, h2, h3, h4, h5, h6 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
    letter-spacing: .4px;
    margin: 0 0 20px;
  }
  p {
    font-size: 14px !important;
    line-height: 20px !important;
    color: $dark !important;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.scroll-top {
  position: fixed;
  right: 30px;
  bottom: 30px;
  width: 40px;
  height: 40px;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  transition: all 350ms;
  line-height: 40px;
  text-align: center;
  cursor: pointer;

  font-size: 36px;
  text-shadow: 0 3px 0 rgba(0,0,0,0.3);
  color: $white;

  &.active {
    visibility: visible;
    opacity: 1;
  }
}

body.menu-active {
  margin-left: 300px;
}

.cart_navigation .button-exclusive {
  margin: 0 !important;
}

#header_user {
  #header_nav {
    display: none;
  }

  a {
    position: relative;
    top: 3px;
  }
}

.td3_payment {
  span {
    display: none;
  }
}
.static_top_out, .and_bottom {
  .col-xs-12 {
    padding: 0;
  }
}
.static_top {
  margin-top: 0;
  cursor: pointer;

  img {
    width: 100%;
    height: auto;
  }
}

.bannerSequence {
  .and_bottom {
    .row {
      background-color: #000;
    }
    .box {
      margin: 0;
      padding: 0;
      position: relative;
      cursor: pointer;

      &__content {
        position: absolute;
        bottom: 50px;
        padding: 0 10%;
        width: 100%;

        &--right {
          text-align: right;
        }
      }
    }

    img {
      width: 100%;
      height: auto;
    }

    h4 {
      position: relative;
      font-size: 4rem;
      padding: 0;
      font-weight: 700;
      line-height: 1;
      color: #fff;
      margin-bottom: 20px;
      text-transform: uppercase;

      @media (max-width: 991px) {
        font-size: 2rem;
      }
    }

    a {
      background-color: #e51e1d;
      color: #fff;
      display: inline-block;
      font-size: 1.9rem;
      padding: 0 15px;
      border-radius: 3px;
      height: 45px;
      line-height: 45px;
      text-transform: uppercase;
      font-weight: 700;

      @media (max-width: 991px) {
        font-size: 1.5rem;
      }
    }
  }
}