.content_scene_cat .content_scene_cat_bg .cat_desc {
  position: static;
}
.product_list {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.page-heading {
  font-weight: 700 !important;
  font-size: 36px;
  border-bottom: 1px solid rgba(0,0,0,0.1) !important;
  margin-bottom: 30px !important;
}

.pagination {
  float: none !important;
  width: auto !important;

  li {
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    transition: all 350ms;

    &.current,
    &:hover {
      background-color: #f5f5f5;
    }
    &.pagination_next,
    &.pagination_previous {
      margin: 0 !important;

      a {
        padding: 2px 0 !important;
      }
    }
    span,
    a {
      border: none !important;
      background: none !important;
      color: $dark;
      display: block;
      width: 100%;
      height: 100%;
      line-height: inherit;

      &:hover {
        color: $dark !important;
      }
    }
  }
}